<template>
  <div class="open_invoice">
    <div class="content">
      <div class="my_break">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path: '/user/myInvoice'}"
            >我的发票</el-breadcrumb-item
          >
          <el-breadcrumb-item>开票申请</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="inner_box">
        <div class="box_title">
          <div class="left">
            <span class="font28 color33">开票申请</span>
            <span class="font14 color66">Invoicing requisition</span>
          </div>
        </div>
        <div class="line"></div>

        <div class="invoice_form">
          <el-form
            label-width="130px"
            class="demo-ruleForm"
            label-position="left"
            :model="invoiceForm"
            ref="invoiceForm"
          >
            <el-form-item label="发票类型：" prop="inType" required>
              <el-radio :label="1" v-model="invoiceForm.inType"
                >增值税电子普通发票</el-radio
              >
            </el-form-item>
            <el-form-item label="抬头类型：" prop="type" required>
              <el-radio-group v-model="invoiceForm.type" @change="changeType">
                <el-radio :label="1">个人</el-radio>
                <el-radio :label="2">企业</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="发票抬头："
              prop="rise"
              required
              :rules="[{required: true, message: '发票抬头', trigger: 'blur'}]"
            >
              <el-input
                placeholder="发票抬头"
                v-model="invoiceForm.rise"
              ></el-input>
            </el-form-item>
            <div v-if="invoiceForm.type != 1">
              <el-form-item
                label="纳税人识别号："
                prop="dutyParagraph"
                required
                :rules="[
                  {required: true, message: '纳税人识别号', trigger: 'blur'},
                ]"
              >
                <el-input
                  placeholder="纳税人识别号"
                  v-model="invoiceForm.dutyParagraph"
                ></el-input>
              </el-form-item>
              <el-form-item label="地址：" prop="address">
                <el-input
                  placeholder="地址"
                  v-model="invoiceForm.address"
                ></el-input>
              </el-form-item>

              <el-form-item label="电话：" prop="phone">
                <el-input
                  placeholder="电话"
                  v-model="invoiceForm.phone"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户行：" prop="openBank">
                <el-input
                  placeholder="开户行"
                  v-model="invoiceForm.openBank"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户行账号：" prop="bankNumber">
                <el-input
                  placeholder="开户行账号"
                  v-model="invoiceForm.bankNumber"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              label="邮箱地址："
              prop="email"
              required
              :rules="[{required: true, message: '邮箱地址', trigger: 'blur'}]"
            >
              <el-input
                placeholder="邮箱地址"
                v-model="invoiceForm.email"
              ></el-input>
            </el-form-item>

            <div class="btn_list">
              <el-button plain class="last_btn" @click="back">取消</el-button>
              <el-button type="primary" class="next_btn" @click="toOpen"
                >提交开票</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {applyInvoicing} from "@/util/api";
export default {
  data() {
    return {
      invoiceForm: {
        type: 1,
        inType: 1,
        orderNo: this.$route.query.orderNo,
      },
    };
  },
  mounted() {
    this.planId = this.$route.query.planId;
  },
  methods: {
    toOpen() {
      this.$refs["invoiceForm"].validate((valid) => {
        if (valid) {
          applyInvoicing(this.invoiceForm).then((res) => {
            this.$message.success(
              "信息已提交，发票将在3-5个工作日开具并发送到预留的邮箱，注意查收"
            );
            this.$router.go(-1);
          });
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    changeType(e) {
      console.log(e);
      if (e != 1) {
      } else {
        this.invoiceForm.dutyParagraph = "";
        this.invoiceForm.address = "";
        this.invoiceForm.phone = "";
        this.invoiceForm.openBank = "";
        this.invoiceForm.bankNumber = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.open_invoice {
  min-height: 100vh;
  background-color: #f5f5f5;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0 100px;
  }
}
.inner_box {
  margin-top: 20px;
  background-color: #fff;
}
.box_title {
  padding: 30px 30px 15px;

  border-radius: 10px 10px 0 0;
}
.line {
  border: 1px solid #cccccc;
}
.invoice_form {
  width: 40%;
  margin: 30px auto;
  padding: 0 0 50px;
}
.btn_list {
  display: flex;
  justify-content: center;
  button {
    width: 130px;
    height: 32px;
    line-height: 32px;
    border-radius: 10px;
    padding: 0;
    line-height: 32px;
    margin: 30px;
  }
  .last_btn {
    background-color: #fff;
    border: 1px solid #2b68d9;
    color: #2b68d9;
  }
  .next_btn {
    background: #2b68d9;
  }
}
</style>
<style scoped>
.invoice_form >>> .el-input__inner {
  border-radius: 10px;
  height: 32px;
  line-height: 32px;
}
</style>
